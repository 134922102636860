import React from "react";

import { bem } from "../../utils";
import "./Contacts.scss";

const classNames = bem("contacts");

export const Contacts = () => (
    <div className={classNames()}>
        <h1>Kontakty</h1>
        <div className={(classNames("content"))}>
            <div className={classNames("left-part")}>
                <h2>Jana Brüstlová</h2>
                <div className={classNames({ smaller: true })}>
                    Hlavní vedoucí<br />
                    +420 723 705 639<br />
                    tabor@tasov.cz<br />
                </div>
            </div>
            <div>
                <h2>Jan Skryja</h2>
                <div className={classNames({ smaller: true })}>
                    Zástupce hlavního vedoucího<br />
                    +420 603 507 439
                </div>
            </div>
        </div>
    </div>
);
